export enum TransactionStatusValues {
  'processing',
  'authorization_succeeded',
  'authorization_declined',
  'authorization_failed',
  'capture_succeeded',
  'capture_pending',
  'authorization_voided',
  'authorization_void_pending',
  'buyer_approval_pending',
}

export type TransactionStatus = keyof typeof TransactionStatusValues

export interface TransactionFilters {
  afterCreatedAt: any
  beforeCreatedAt: any
  buyerExternalIdentifier: string
  buyerId: string
  cursor: string
  limit: number
  search: string
  transactionStatus: TransactionStatus | TransactionStatus[]
  method: string
  paymentServiceId: string
  paymentMethodId: string
  hasRefunds: boolean
  pendingReview: boolean
  checkoutSessionId?: string
  giftCardId: string
  currency: string
}
