import { useState } from 'react'
import { ReportTemplate, reportTemplates } from 'reports/constants'
import { env } from 'shared/constants/env'

export const useReportTemplates = () => {
  const [template, setTemplate] = useState<{
    id: string
    isCustom: boolean
    schedule: boolean
  } | null>(null)
  const isSettlementTemplate = template?.id === 'settlement'
  const isSettlementReportEnabled = env.REACT_APP_SETTLEMENT_REPORT === 'true'

  const filteredReportTemplates: ReportTemplate[] = isSettlementReportEnabled
    ? reportTemplates
    : reportTemplates.filter((template) => template.id !== 'settlement')

  return {
    template,
    setTemplate,
    isSettlementTemplate,
    isSettlementReportEnabled,
    reportTemplates: filteredReportTemplates,
  }
}
